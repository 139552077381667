import React from "react";
import { Link } from "react-router-dom";
import "./Product.css";

export default function Product({ product, delay }) {
  return (
    <div className="product" style={{ animationDelay: `${delay / 4}s` }}>
      <div className="product-content">
        <h3 className="product-title">{product.title}</h3>
        <p className="product-description">{product.description}</p>
        <ul className="product-benefits">
          {product.benefits.map((benefit) => (
            <li key={benefit}>{benefit}</li>
          ))}
        </ul>
        <div>{product.conclusion}</div>
      </div>
      <strong className="product-call-to-action">
        Interested?&nbsp;
        <Link to={"/contact"}>Contact us</Link>
        &nbsp;to learn more!
      </strong>
    </div>
  );
}
