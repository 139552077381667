import React, { useEffect } from "react";
import "./Hero.css";
import { Button } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import Typed from "typed.js";

export default function Hero() {
  useEffect(() => {
    let typed = new Typed("#rotating-text", {
      strings: [
        "Final Expense Insurance",
        "Term Life Insurance",
        "Whole Life Insurance",
        "Mortgage Protection Insurance",
        "Fixed Index Annuities",
        "Retirement Planning",
      ],
      typeSpeed: 50,
      backSpeed: 50,
      loop: true,
    });

    return () => {
      typed.destroy();
    };
  }, []);

  return (
    <div id="hero">
      {/* <picture className="hero-img">
        <source media="(max-width: 900px)" srcset="fam.png" />
        <img className="hero-img" src="images/elderly-couple-bench1.jpeg"></img>
      </picture> */}
      <img
        className="hero-img"
        src="images/family-hero.webp"
        alt="Happy family"
      ></img>
      <div className="hero-text">
        <img
          id="hero-logo"
          src="images/logo-white.png"
          alt="Benefits Center Logo Large"
        ></img>
        <h1>Protect what matters most</h1>
        <div id="hero-p">
          {/* At benefits center, we believe that protecting your family's future is
          essential, however that may look for you. That's why we offer a wide
          range of services such as
          <br /> */}
          <span id="rotating-text"></span>
        </div>

        <Link id="book-now" to="/contact">
          <strong>Get a Quote</strong>
          <div id="arrow">
            <div id="div1"></div>
            <div id="div2"></div>
          </div>
        </Link>
      </div>
    </div>
  );
}
