import React from "react";
import "./Service.css";
import { Link } from "react-router-dom";

export default function Service({ service, delay }) {
  return (
    <div className="service" style={{ animationDelay: `${delay / 4}s` }}>
      <div>
        <h3>{service.title}</h3>
        <div>{service.description}</div>
      </div>
      <Link className="learn-more" to={`/products/group/${service.id}`}>
        Learn More
      </Link>
    </div>
  );
}
