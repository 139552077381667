import React, { useEffect } from "react";
import "./Products.css";
import Product from "./Product/Product";
import ShortProduct from "./ShortProduct/ShortProduct";
import data from "./products.json";
import {
  Outlet,
  Route,
  Routes,
  useLocation,
  useParams,
} from "react-router-dom";
const products = data.products;
const groups = data.groups;

export default function Products() {
  console.log(useLocation());
  let { product_id } = useParams();

  let title;
  let content;
  let numCols;

  useEffect(() => {
    document.title = "Products";
  }, []);

  if (!product_id) {
    title = "Products";
    numCols = "1fr 1fr";
    content = products.map((product, i) => (
      <ShortProduct key={product.id} product={product} delay={i} />
    ));
  } else {
    let product = products.find(
      (product) => product.id === parseInt(product_id)
    );
    title = product.title;
    numCols = "1fr";
    content = <Product key={product.id} product={product} delay={0} />;
  }

  return (
    <div className="products">
      <h1 className="products-title">{title}</h1>
      <div
        style={{ gridTemplateColumns: `${numCols}` }}
        className="products-container"
      >
        {content}
      </div>
    </div>
  );
}
