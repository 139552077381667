import React from "react";
import { ChakraProvider, Box } from "@chakra-ui/react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import NavBar from "./components/Nav/Nav";
import Footer from "./components/Footer/Footer";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import Home from "./pages/Home/Home";
import Products from "./pages/Products/Products";
import ProductGroup from "./pages/Products/ProductGroup/ProductGroup";
import Contact from "./pages/Contact/Contact";
import { useEffect } from "react";

function App() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <ChakraProvider>
      <Box className="app">
        <Router>
          <NavBar />
          <ScrollToTop />
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route
              path="/products/group/:group_id"
              element={<ProductGroup />}
            />
            <Route path="/products" element={<Products />}>
              <Route path=":product_id" element={<Products />} />
            </Route>
            <Route path="/contact" element={<Contact />} />
          </Routes>
          <Footer />
        </Router>
      </Box>
    </ChakraProvider>
  );
}

export default App;
