import React, { useEffect } from "react";
import "./Carriers.css";
import carriers from "./carriers.json";
import { wait } from "@testing-library/user-event/dist/utils";

export default function Carriers() {
  // Divs as state variable.
  const [divs, setDivs] = React.useState(<p>Nothing to see here</p>);

  useEffect(() => {
    populateCarriers();
    window.onresize = populateCarriers;
    waitForLogos();
  }, []);

  function populateCarriers() {
    let divs = [];
    for (let i = 0; i < Math.ceil(carriers.length / 3) + 1; i++) {
      let divElements = [];
      let cols = 3;
      if (window.innerWidth < 900) cols = 2;
      if (window.innerWidth < 600) cols = 1;

      for (let j = i * cols; j < i * cols + cols; j++) {
        if (j < carriers.length) {
          divElements.push(
            <a key={carriers[j].id} href={carriers[j].url}>
              <img
                className="carrier-logo"
                src={`images/${carriers[j].logo}`}
                alt={carriers[j].alt}
              ></img>
            </a>
          );
        }
      }
      divs.push(
        <div key={i} className="carrier-row">
          {divElements}
        </div>
      );
    }
    setDivs(divs);
  }

  function waitForLogos() {
    let carrierLogos = document.querySelectorAll(".carrier-logo");
    if (carrierLogos.length > 0) {
      addIntersectionObserver();
    } else {
      setTimeout(waitForLogos, 100);
    }
  }

  function addIntersectionObserver() {
    let carrierLogos = document.querySelectorAll(".carrier-logo");

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            console.log("intersecting");
            entry.target.classList.add("slideUpAndAppear");
          }
        });
      },
      { threshold: 1 }
    );

    carrierLogos.forEach((logo) => {
      observer.observe(logo);
    });
  }

  return (
    <div className="carriers">
      <h2 className="carriers-title">Carriers</h2>
      <div className="carrier-logos">{divs}</div>
    </div>
  );
}
