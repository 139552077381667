import React from "react";
import { Text, Link } from "@chakra-ui/react";
import "./Footer.css";

function Footer() {
  return (
    <footer>
      <div className="footer-links">
        <Text fontWeight="semibold">© 2023 Benefits Center</Text>
        <Text fontWeight="semibold">
          Call us:{" "}
          <Link href="tel:800-901-6304" color="#0c449a">
            800-901-6304
          </Link>
        </Text>
        <Text fontWeight="semibold">
          Email us:&nbsp;
          <Link href="mailto:info@benefitscenter.org" color="#0c449a">
            info@benefitscenter.org
          </Link>
        </Text>
      </div>
      <div className="social-icons">
        <a href="https://www.facebook.com/mybenefitscenter" target="_blank">
          <img
            className="facebook-logo social-icon"
            src="images/facebook-logo-black.png"
            alt="Facebook Logo"
          ></img>
        </a>
        <a href="https://www.instagram.com/mybenefitscenter/" target="_blank">
          <img
            className="instagram-logo social-icon"
            src="images/instagram-logo.svg"
            alt="Instagram Logo"
          ></img>
        </a>
        <a href="https://www.tiktok.com/@benefitscenter" target="_blank">
          <img
            className="tiktok-logo social-icon"
            src="images/tiktok-logo.svg"
            alt="TikTok Logo"
          ></img>
        </a>
      </div>
    </footer>
  );
}

export default Footer;
