import React from "react";
import { useEffect } from "react";
import Hero from "./sections/Hero/Hero";
import Services from "./sections/Services/Services";
import AgentSpotlight from "./sections/AgentSpotlight/AgentSpotlight";
import WhyInsurance from "./sections/WhyInsurance/WhyInsurance";
import ContactUs from "./sections/ContactUs/ContactUs";
import Carriers from "./sections/Carriers/Carriers";
import "./Home.css";

function Home() {
  useEffect(() => {
    document.title = "Home";
  }, []);

  return (
    <div className="home">
      <Hero />
      <Services />
      <WhyInsurance />
      <Carriers />
    </div>
  );
}

export default Home;
