import React from "react";
import Service from "./Service/Service";
import services from "./services.json";
import "./Services.css";

export default function Services() {
  return (
    <div id="services-container">
      <h2 id="services-title">Services</h2>
      <div className="services">
        {services.map((service, i) => (
          <Service key={service.id} service={service} delay={i} />
        ))}
      </div>
    </div>
  );
}
