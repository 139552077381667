import "./ShortProduct.css";
import { Link } from "react-router-dom";

export default function ShortProduct({ product, delay }) {
  return (
    <div className="short-product" style={{ animationDelay: `${delay / 4}s` }}>
      <h3>{product.title}</h3>
      <p>{product.abbrDescription}</p>
      <Link to={`/products/${product.id}`}>Learn more</Link>
    </div>
  );
}
