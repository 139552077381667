import React, { useEffect } from "react";
import "./Contact.css";
import Form from "../../components/Form/Form";

export default function Contact() {
  useEffect(() => {
    document.title = "Contact Us";
  }, []);

  return (
    <div className="contact">
      <h1 className="contact-us-title">Contact Us</h1>
      <p className="contact-us-text">
        Fill out this form and an agent will reach out to you as soon as
        possible!
      </p>
      <Form />
    </div>
  );
}
