import React, { useEffect } from "react";
import "./WhyInsurance.css";

export default function WhyInsurance() {
  useEffect(() => {
    const whyInsuranceItems = document.querySelectorAll(".why-insurance-item");

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("slideFromLeftAndAppear");
          }
        });
      },
      { threshold: 1 }
    );

    whyInsuranceItems.forEach((item) => {
      observer.observe(item);
    });
  }, []);

  return (
    <div className="why-insurance">
      <picture className="why-insurance-image">
        <source media="(max-width: 1720px)" srcSet="images/house-hands.webp" />
        <img
          className="why-insurance-image"
          src="images/elderly-couple-walk.jpeg"
          alt="An elderly couple takes a walk"
        ></img>
      </picture>
      <div className="why-insurance-content">
        <h2 className="why-insurance-title">Why Invest in Insurance?</h2>
        <div id="why-insurance-item-1" className="why-insurance-item">
          <div className="why-insurance-item-title-container">
            <div className="why-insurance-item-number">
              <h3>1</h3>
            </div>
            <h3 className="why-insurance-item-title">Hope for the best</h3>
          </div>
          <div className="why-insurance-item-text">
            We all hope we will live long lives free from unexpected health
            burdens such as cancer, heart attacks, or strokes, but the reality
            is we need to prepare for the worst.
          </div>
        </div>
        <div id="why-insurance-item-2" className="why-insurance-item">
          <div className="why-insurance-item-title-container">
            <div className="why-insurance-item-number">
              <h3>2</h3>
            </div>
            <h3 className="why-insurance-item-title">Prepare for the worst</h3>
          </div>
          <div className="why-insurance-item-text">
            The good news is we are living longer than ever before, but
            sometimes we are hit with the unexpected. Chronic illness or
            disability can deplete financial assets and devastate our savings
            accounts. However, we can minimize the impact of these unexpected
            events by having a plan.
          </div>
        </div>
        <div id="why-insurance-item-3" className="why-insurance-item">
          <div className="why-insurance-item-title-container">
            <div className="why-insurance-item-number">
              <h3>3</h3>
            </div>
            <h3 className="why-insurance-item-title">Make a plan</h3>
          </div>
          <div className="why-insurance-item-text">
            The biggest act of love you can provide for your family is to make a
            plan to take care of them when you're no longer around. Don't leave
            them with the burden of financial stress and uncertainty. We can
            help you make a plan that fits your budget and lifestyle.
          </div>
        </div>
      </div>
    </div>
  );
}
