import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import "./Form.css";
import data from "./form.json";

export default function ContactForm() {
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    let healthRatingRadios = document.getElementsByClassName(
      "health-rating-radio"
    );

    for (let i = 0; i < healthRatingRadios.length; i++) {
      healthRatingRadios[i].addEventListener(
        "change",
        updateHealthRatingStyles
      );
    }

    let tobaccoOptions = document.getElementsByClassName("tobacco-option");

    for (let i = 0; i < tobaccoOptions.length; i++) {
      tobaccoOptions[i].addEventListener("change", updateTobaccoStyles);
    }
  });

  function updateHealthRatingStyles(e) {
    let radios = document.getElementsByClassName("health-rating-radio");

    for (let i = 0; i < radios.length; i++) {
      let parent = radios[i].parentElement.parentElement;
      radios[i].checked
        ? parent.classList.add("selected")
        : parent.classList.remove("selected");
    }
  }

  function updateTobaccoStyles(e) {
    let tobaccoOptions = document.getElementsByClassName("tobacco-option");

    for (let i = 0; i < tobaccoOptions.length; i++) {
      let parent = tobaccoOptions[i].parentElement;
      tobaccoOptions[i].checked
        ? parent.classList.add("selected")
        : parent.classList.remove("selected");
    }
  }

  function togglePopup(e) {
    e.target.classList.contains("popup")
      ? e.target.classList.remove("popup")
      : e.target.classList.add("popup");
  }

  return !submitted ? (
    <div className="form">
      <Formik
        initialValues={{
          fname: "",
          lname: "",
          dob: "",
          phone: "",
          email: "",
          address: "",
          city: "",
          state: "",
          zip: "",
          beneficiary: "",
        }}
        validationSchema={Yup.object({
          fname: Yup.string().required("This field is required"),
          lname: Yup.string().required("This field is required"),
          dob: Yup.string().required("This field is required"),
          phone: Yup.string()
            .matches(
              /^[1-9]?\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/,
              "Please enter a valid phone number"
            )
            .required("This field is required"),
          email: Yup.string()
            .email("Please enter a valid email")
            .required("This field is required"),
          address: Yup.string().required("This field is required"),
          city: Yup.string().required("This field is required"),
          state: Yup.string()
            .oneOf(
              data.states.map((state) => state.abbreviation),
              "Please select a state"
            )
            .required("This field is required"),
          zip: Yup.string()
            .length(5, "Zip must be 5 digits long")
            .required("This field is required"),
          coverageType: Yup.string().required("This field is required"),
          coverageAmount: Yup.string().required("This field is required"),
          beneficiary: Yup.string().required("This field is required"),
        })}
        onSubmit={async (values) => {
          console.log(values);

          let headers = new Headers();
          headers.append("Content-Type", "application/json");

          let options = {
            method: "POST",
            headers,
            body: JSON.stringify(values),
          };

          let response = await fetch(
            "https://benefits-center-backend.herokuapp.com/contact",
            options
          );

          if (response.ok) {
            setSubmitted(true);
          }
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <fieldset className="personal-info">
              <legend>Personal Info</legend>
              <div id="first-row">
                <label className="input-label" id="fname">
                  &nbsp;First Name
                  <Field type="text" name="fname" className="input-box" />
                  <ErrorMessage
                    component="div"
                    name="fname"
                    className="error-message"
                  />
                </label>
                <label className="input-label" id="lname">
                  &nbsp;Last Name
                  <Field type="text" name="lname" className="input-box" />
                  <ErrorMessage
                    component="div"
                    name="lname"
                    className="error-message"
                  />
                </label>
                <label className="input-label" id="dob">
                  &nbsp;Date of Birth
                  <Field type="date" name="dob" className="input-box" />
                  <ErrorMessage
                    component="div"
                    name="dob"
                    className="error-message"
                  />
                </label>
              </div>
              <label className="input-label">
                &nbsp;Phone
                <Field type="tel" name="phone" className="input-box" />
                <ErrorMessage
                  component="div"
                  name="phone"
                  className="error-message"
                />
              </label>
              <label className="input-label">
                &nbsp;Email
                <Field type="email" name="email" className="input-box" />
                <ErrorMessage
                  component="div"
                  name="email"
                  className="error-message"
                />
              </label>
              <label className="input-label">
                &nbsp;Address
                <Field type="text" name="address" className="input-box" />
                <ErrorMessage
                  component="div"
                  name="address"
                  className="error-message"
                />
              </label>
              <label className="input-label">
                &nbsp;City
                <Field type="text" name="city" className="input-box" />
                <ErrorMessage
                  component="div"
                  name="city"
                  className="error-message"
                />
              </label>
              <label className="input-label">
                &nbsp;State
                <Field as="select" name="state" className="input-box">
                  <option value="">Select a State</option>
                  {data.states.map((state) => (
                    <option key={state.abbreviation} value={state.abbreviation}>
                      {state.name}
                    </option>
                  ))}
                </Field>
                <ErrorMessage
                  component="div"
                  name="state"
                  className="error-message"
                />
              </label>
              <label className="input-label">
                &nbsp;Zip Code
                <Field type="number" name="zip" className="input-box" />
                <ErrorMessage
                  component="div"
                  name="zip"
                  className="error-message"
                />
              </label>
              {/* <label className="input-label">
                &nbsp;What service are you interested in?
                <Field
                  as="select"
                  className="input-box"
                  name="coverage-type"
                  onChange={(e) => setSelectedService(e.target.value)}
                >
                  <option value="">Select a Service</option>
                  {data.coverageTypes.map((type) => (
                    <option key={type.id} value={type.id}>
                      {type.type}
                    </option>
                  ))}
                </Field>
              </label> */}
            </fieldset>
            <fieldset className="medical-history">
              <div className="medical-history-title-container">
                <legend>Medical Info</legend>
                <span onClick={togglePopup} className="info-icon">
                  &nbsp;&#9432;
                </span>
              </div>
              <label className="input-label" htmlFor="excellent">
                &nbsp;How would you rate your overall health?
              </label>
              <div className="health-rating-container">
                <div className="health-rating-option">
                  <label>
                    &nbsp;
                    <Field
                      className="health-rating-radio"
                      type="radio"
                      name="healthRating"
                      value="1"
                      id="excellent"
                      required
                    />
                    &nbsp;Excellent
                    <ul>
                      <li>Healthy weight</li>
                      <li>Normal cholestrol</li>
                      <li>Normal blood pressure</li>
                      <li>No medical conditions</li>
                    </ul>
                  </label>
                </div>
                <div className="health-rating-option">
                  <label>
                    &nbsp;
                    <Field
                      className="health-rating-radio"
                      type="radio"
                      name="healthRating"
                      value="2"
                      id="good"
                      required
                    />
                    &nbsp;Good
                    <ul>
                      <li>Normal weight</li>
                      <li>May need medication for cholestrol</li>
                      <li>May need medication for blood pressure</li>
                      <li>Minor pre-existing medical conditions</li>
                    </ul>
                  </label>
                </div>
                <div className="health-rating-option">
                  <label>
                    &nbsp;
                    <Field
                      className="health-rating-radio"
                      type="radio"
                      name="healthRating"
                      value="3"
                      id="fair"
                      required
                    />
                    &nbsp;Fair
                    <ul>
                      <li>Overweight</li>
                      <li>High cholestrol</li>
                      <li>High blood pressure</li>
                      <li>Some pre-existing medical conditions</li>
                    </ul>
                  </label>
                </div>
              </div>
              <label className="input-label">
                &nbsp;Have you experienced any of the following medical
                conditions? Mark all that apply.
                <div id="medical-conditions">
                  {data.medicalConditions.map((condition) => (
                    <label key={condition.id} className="checkbox-input-label">
                      <Field
                        className="medical-condition"
                        name="medicalConditions"
                        type="checkbox"
                        value={condition.condition}
                      />
                      &nbsp;{condition.condition}
                    </label>
                  ))}
                </div>
              </label>
              <div id="tobacco-container">
                <label className="input-label">
                  &nbsp;Do you use tobacco, e-cigarettes, or other
                  nicotine-containing products?
                </label>
                <label className="tobacco-option-label">
                  <Field
                    className="tobacco-option"
                    type="radio"
                    name="tobacco"
                    id="yes"
                    value="true"
                    required
                  />
                  Yes
                </label>
                <label className="tobacco-option-label">
                  <Field
                    className="tobacco-option"
                    type="radio"
                    name="tobacco"
                    id="no"
                    value="false"
                  />
                  No
                </label>
              </div>
            </fieldset>
            <fieldset className="coverage-info">
              <legend>Coverage Info</legend>
              <label className="input-label">
                &nbsp;What service are you interested in?
                <Field as="select" className="input-box" name="coverageType">
                  <option value="">Select a Service</option>
                  {data.coverageTypes.map((type) => (
                    <option key={type.id} value={type.id}>
                      {type.type}
                    </option>
                  ))}
                </Field>
                <ErrorMessage
                  component="div"
                  name="coverageType"
                  className="error-message"
                />
              </label>
              <label className="input-label">
                &nbsp;What amount of coverage are you looking for?
                <Field
                  as="select"
                  className="input-box"
                  name="coverageAmount"
                  required
                >
                  <option value="">Select a Coverage Amount</option>
                  {data.coverageAmounts.map((amount) => (
                    <option key={amount.id} value={amount.id}>
                      {amount.amount}
                    </option>
                  ))}
                </Field>
                <ErrorMessage
                  component="div"
                  name="coverageAmount"
                  className="error-message"
                />
              </label>
              <label className="input-label beneficiary">
                &nbsp;Who is your beneficiary?
                <Field
                  className="input-box"
                  type="text"
                  name="beneficiary"
                  required
                />
                <ErrorMessage
                  component="div"
                  name="beneficiary"
                  className="error-message"
                />
              </label>
            </fieldset>
            <div className="submit-container">
              <input
                className="submit-button"
                type="submit"
                value="Submit"
                disabled={isSubmitting}
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  ) : (
    <div className="submit-message">
      <h1>Your form was successfully submitted!</h1>
      <p>An agent will reach out to you shortly</p>
    </div>
  );
}
