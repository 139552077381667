import "./ProductGroup.css";
import Product from "../Product/Product";
import data from "../products.json";
import { useParams } from "react-router-dom";
const products = data.products;
const groups = data.groups;

export default function ProductGroup() {
  const { group_id } = useParams();

  let numCols = "1fr 1fr";
  let filtered = products.filter(
    (product) => product.group === parseInt(group_id)
  );
  if (filtered.length === 1) numCols = "1fr";

  return (
    <div className="product-group">
      <h1 className="product-group-title">{groups[group_id]}</h1>
      <div
        className="product-group-container"
        style={{ gridTemplateColumns: numCols }}
      >
        {filtered
          .filter((product) => product.group === parseInt(group_id))
          .map((product, i) => (
            <Product key={product.id} product={product} delay={i} />
          ))}
      </div>
    </div>
  );
}
